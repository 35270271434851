import React from "react";
import "../App.css";

const axios = require("axios");

const url_home = process.env.REACT_APP_URL;
const thresh = process.env.REACT_APP_HOOK_SERVICE;

export const Callback = () => {
  console.log("Hola mundo");
  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");

  if (!code) {
    window.location.href = url_home + "?status=false";
  }

  const at = process.env.REACT_APP_ACCESS_TOKEN;
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const client_secret = process.env.REACT_APP_CLIENT_SECRET;
  const url_callback = process.env.REACT_APP_CALLBACK;
  const g_type = "authorization_code";

  const ObtenerToken = async () => {
    const objetct = {
      client_id: client_id,
      client_secret: client_secret,
      grant_type: g_type,
      code: code,
      redirect_uri: url_callback,
    };

    console.log(objetct);

    try {
      const resp = await axios.request({
        url: at,
        method: "post",
        baseURL: url_callback,
        auth: {
          username: client_id, // This is the client_id
          password: client_secret, // This is the client_secret
        },
        data: {
          code: code,
          grant_type: "authorization_code",
          redirect_uri: url_callback,
        },
      });

      try {
        localStorage.setItem("user", JSON.stringify(resp));
        var bToData = JSON.parse(localStorage.getItem("user"));
        console.log(bToData.data.access_token);
        if (bToData.data.access_token) {
          //Validar Inyeccion de Javascript
          //Get JS Inyection
          // const url = 'v1/jsapps/'
          var sw = false;
          try {
            console.log("pasa aca");
            const getInfo = await axios.get(
              `${thresh}/jumpseller/getJsapps/${bToData.data.access_token}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            );
            console.log(getInfo.data.data);
            for (const datos of getInfo.data.data) {
              console.log(datos.js_app.url);
              let url = datos.js_app.url;
              if (!url.includes("InyeccionGarantiaCarrito")) {
                sw = false;
              } else {
                sw = true;
                break;
              }
            }
            console.log(sw);
          } catch (e) {
            console.log("pasa aca 2");
            //Crear Inyeccion de JS
            //OBS URL: El archivo siempre debe estar en la nube.
            await axios.post(`${thresh}/jumpseller/createJsapps`, {
              token: `${bToData.data.access_token}`,
            });
          }

          // console.log(getInfo.data)
          var sw2 = false;
          try {
            console.log("pasa aca 2");
            const getHook = await axios.get(`${thresh}/jumpseller/getHooks/`, {
              headers: {
                token: `${bToData.data.access_token}`,
              },
            });
            console.log(getHook.data.data);
            for (const datos of getHook.data.data) {
              console.log(datos.hook.url);
              //https://perk.cl/thresh/api/v1/hook
              let url = datos.hook.url;
              if (!url.includes("perk.cl")) {
                sw2 = false;
              } else {
                sw2 = true;
                break;
              }
            }

            console.log(sw);
            console.log(sw2);
          } catch (e) {
            console.log("pasa aca 2");
            //Crear Inyeccion de JS
            //OBS URL: El archivo siempre debe estar en la nube.
            await axios.post(
              `${thresh}/jumpseller/RegisterHook`,
              {
                url: `${thresh}/hook`,
              },
              {
                token: `${bToData.data.access_token}`,
              }
            );
          }

          if (sw === false) {
            //Crear Inyeccion de JS
            //OBS URL: El archivo siempre debe estar en la nube.
            await axios.post(`${thresh}/jumpseller/createJsapps`, {
              token: `${bToData.data.access_token}`,
            });
          }

          if (sw2 === false) {
            //Crear Hook
            //OBS URL:  Siempre es la misma dependiendo del servicio.
            console.log(thresh);
            console.log("entro en hook");

            await axios.post(
              `${thresh}/jumpseller/RegisterHook`,
              {
                url: `${thresh}/hook`,
              },
              {
                headers: {
                  token: `${bToData.data.access_token}`,
                },
              }
            );
          }
          //console.log(sw)
          //Crear campo personalizado si no existe da 404
          try{
            await axios.post(
              `${thresh}/jumpseller/createCustomField`,
              {},
              {
                headers: {
                  token: `${bToData.data.access_token}`,
                },
              }
            );
          }catch(error){
            console.log(error)
          }finally{
            window.location.href = url_home + "/home";
          }
        } else {
          alert(
            "Ocurrio un error al generar el token seras redirigido al login."
          );
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App-header">
      <h1>Inicio de Sesion Jumpseller Exitoso</h1>
      <p>Para continuar de click en Entrar</p>
      <>
        {!code ? (
          <p>Error - Su codigo no es valido</p>
        ) : (
          <button className="button" onClick={ObtenerToken}>
            {" "}
            Entrar
          </button>
        )}
      </>
    </div>
  );
};

export default Callback;
