import React from 'react'
import './App.css';

import { RouterPrincipal } from './routers/RouterPrincipal';
export const App = () => {
  return (
    <RouterPrincipal/>
  )
}

export default App;
