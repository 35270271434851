import * as FaIcons from "react-icons/fa";

const Sidebar = () => {
  const Refresh = async (e) => {
    window.location.reload();
  };

  return (
    <div className="sidebar">
      <ul>
        <li>
          <span className="textcolor" id="btnInicio" onClick={Refresh}>
            <FaIcons.FaHome className="me-2" /> Inicio{" "}
          </span>
        </li>
        <li>
          <a
            href="https://embedx.io/"
            target="_blank"
            rel="noreferrer noopener"
            className="textcolor"
          >
            <FaIcons.FaInfoCircle className="me-2" />
            Nosotros
          </a>
        </li>
        <li>
          <a href="/" className="textcolor">
            <FaIcons.FaDoorOpen className="me-2" />
            Salir
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
