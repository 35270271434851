const Navbar = () => {
    return (
        <div className="navbar">
            <img src="logoembedx.jpg" alt="Logotipo" className="logo"/>
                
        </div>
    )
}

export default Navbar


