import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import "../Home.scss";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Loader } from "./Loader";

export const Home = () => {
  const vault = process.env.REACT_APP_URL_VAULT;
  const shield = process.env.REACT_APP_URL_SHIELD;
  const path = process.env.REACT_APP_URL;
  const thresh = process.env.REACT_APP_HOOK_SERVICE;
  const { register, getValues } = useForm();
  const [loading, changeStateLoading] = useState(false);
  const [plans, getPlans] = useState(null);
  const [categorias_embx] = useState("Vacio");
  const [categoria, getCategoria] = useState(null);

  var bToData = JSON.parse(localStorage.getItem("user"));

  if (!localStorage.getItem("user")) {
    localStorage.removeItem("user");
    localStorage.removeItem("embedx");
    window.location.href = `${path}?status=false`;
  }

  const Login = async (e) => {
    const user = getValues("user");
    const pass = getValues("pass");
    console.log(user);
    console.log(pass);
    console.log(shield);

    try {
      Swal.fire({
        title: "Cargando",
        text: "Iniciando Sesion en los sistemas de EmbedX",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/disp/1f430a36197347.57135ca19bbf5.gif",
        imageAlt: "Custom image",
        animation: false,
        timer: 999999,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      const xtoken = await axios.post(`${shield}/user/login`, {
        email: user,
        password: pass,
      });
      console.log("data", xtoken.data.data.sponsorsGext[0].name);
      if (xtoken.status === 200) {
        const categoriesJumpSeller = await axios.get(
          `${thresh}/jumpseller/getCategories/${bToData.data.access_token}`
        );
        console.log(categoriesJumpSeller);
        var selectJS = document.getElementById("jumpseller_cat");
        var categoryJumpseller = categoriesJumpSeller.data.data;
        var categoriasJumpseller = [];

        categoryJumpseller.forEach(function (value, index, array) {
          categoriasJumpseller.push(array[index].category.name);
          let option = document.createElement("option");
          option.text = array[index].category.name;
          option.value = array[index].category.id;
          selectJS.appendChild(option);
        });

        localStorage.setItem("embedx", JSON.stringify(xtoken.data));
        var embedData = JSON.parse(localStorage.getItem("embedx"));
        //aca va el objeto para enviar el refresh token
        console.log("DATA EMBEDX");
        console.log(embedData.data.sponsorsGext[0].name);
        console.log(bToData.data.refresh_token);
        var objetoData = {
          sponsorName: embedData.data.sponsorsGext[0].name,
          refreshToken: bToData.data.refresh_token,
          createdAt: bToData.data.created_at,
          expiresIn: bToData.data.expires_in,
        };
        
        await axios.post(
          `${thresh}/jumpseller/createRefreshData`,
          objetoData,
          {
            headers: {
              "x-access-token": embedData.accessToken,
            },
          }
        );

        const categories = await axios.get(`${vault}/category/get`, {
          headers: {
            "x-access-token": embedData.accessToken,
          },
        });

        var select = document.getElementById("embed_cat");
        var category = categories.data.data;
        var categoriasEmbed = [];

        category.forEach(function (value, index, array) {
          categoriasEmbed.push(array[index].name);
          let option = document.createElement("option");
          option.text = array[index].name;
          option.value = array[index].name + index;
          select.appendChild(option);
        });

        document.getElementById("sesion").disabled = true;
        document.getElementById("user").disabled = true;
        document.getElementById("pass").disabled = true;

        var sponsorFilter = xtoken.data.data.sponsorsGext[0].name;
        console.log(sponsorFilter);
        const relations = await axios.get(
          `${vault}/relation/get/${sponsorFilter}`,
          {
            headers: {
              "x-access-token": embedData.accessToken,
            },
          }
        );

        console.log(relations.status);
        if (relations.status === 201) {
          Swal.fire(
            "Operacion Exitosa",
            "Inicio de Sesion Correcto, no posee historial de garantias",
            "info"
          );
        } else {
          var objetoJson = [];
          console.log(relations.data.data.length);
          var cont = 0;

          relations.data.data.map(function () {
            objetoJson.push(relations.data.data[cont]);
            cont++;
          });

          console.log("OBJETO");
          var categoria_tabla;
          categoria_tabla = objetoJson;
          console.log(categoria_tabla);
          if (categoria_tabla.length === 0) categoria_tabla = null;
          getCategoria(categoria_tabla);

          Swal.fire(
            "Operacion Exitosa",
            "Inicio de Sesion Correcto",
            "success"
          );
        }
        // aqui va el hook de la tabla
      } else {
        changeStateLoading("");
        Swal.fire(
          "Error",
          "Error al Hacer Login en EmbedX Contactese con el Administrador",
          "error"
        );
      }
    } catch (e) {
      changeStateLoading("");
      Swal.fire("Error", "Error Credenciales Invalidas: " + e, "error");
      //swal("Error Credenciales Invalidas",e,"error")
    }
  };

  const AddWarranty = async (e) => {
    var idJumpseller;
    Swal.fire({
      title: "Cargando",
      text: "No cierre la ventana hasta que finalice la creacion de los planes.",
      imageUrl:
        "https://mir-s3-cdn-cf.behance.net/project_modules/disp/1f430a36197347.57135ca19bbf5.gif",
      imageAlt: "Custom image",
      animation: false,
      timer: 999999,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    var message_swal = "Error al momento de Agregar la Garantia";
    var title_swal =
      "Verifique que existan productos en la categoria seleccionada";
    var tipe_swal = "error";

    changeStateLoading(true);
    //const emb_select_value = getValues("embed_select") ; algun dia
    const jumpseller_select_value = getValues("js_select");
    //labels
    var embed_label = document.getElementById("embed_cat");
    var label_option_embed =
      embed_label.options[embed_label.selectedIndex].text;

    var js_label = document.getElementById("jumpseller_cat");
    var js_option_embed = js_label.options[js_label.selectedIndex].text;

    if (js_option_embed === "Vacio" || label_option_embed === "Vacio") {
      changeStateLoading("");
      //swal("Tiene que Seleccionar alguna categoria para generar la garantia","Error","error")
      Swal.fire(
        "Debe Seleccionar las categorias",
        "Seleccione Correctamente las categorias",
        "info"
      );
    } else {
      //Get Categories Thresh
      const getCategories = await axios.get(
        `${thresh}/jumpseller/getProducts/${bToData.data.access_token}&${jumpseller_select_value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Producto");
      console.log(getCategories.data);

      //INICIO INFO TIENDA--------------------------
      // const url = '/v1/store/info.json'
      // const getInfo = await axios.get(`${url}`,{
      //     headers : {
      //         'Content-Type': 'application/json',
      //         'Accept': 'application/json',
      //         'Authorization': `Bearer ${bToData.data.access_token}`
      //     }
      // })}

      const getInfo = await axios.get(
        `${thresh}/jumpseller/getInfoStore/${bToData.data.access_token}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("DImelo info");
      console.log(getInfo.data.data.store.url);
      var plan_filter = getInfo.data.data.store.url;
      //FIN INFO TIENDA ----------------------------

      var embedData = JSON.parse(localStorage.getItem("embedx"));
      console.log(embedData.accessToken);
      const getKeys = await axios.post(
        `${shield}/apiKey/create`,
        {},
        {
          headers: {
            "x-access-token": embedData.accessToken,
          },
        }
      );

      var apikey = getKeys.data.data.key;
      var secret = getKeys.data.data.secret;

      for (const producto of getCategories.data.data) {
        try{
        console.log("Inicio Iteracion -----------------");
        console.log(producto.product); // General
        console.log(producto.product.name); // nombre
        console.log(producto.product.price); // precio
        console.log(producto.product.stock); // stock new
        console.log(producto.product.sku); // sku new
        console.log(producto.product.categories[0].name); // categoria
        console.log(plan_filter);

        console.log("Fin Iteracion -----------------");

        //stock embedx asign stock
        console.log("id producto para crear stock padre")
        console.log(producto.product.id)
        await axios.post(
          `${thresh}/jumpseller/createCustomInProduct`,
          {
            idProduct: producto.product.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              token: `${bToData.data.access_token}`,
            },
          }
        );

         



        const getPlan = await axios.get(
          `${vault}/plan/get?sponsor=${plan_filter}&price=${producto.product.price}&category=${js_option_embed}`,
          {
            headers: {
              API_KEY: apikey,
              API_SECRET: secret,
            },
          }
        );

        console.log(getPlan.data.plans);
        if (getPlan.data.plans.length <= 0) {
          message_swal = "No hay concidencias";
          title_swal = "No se encontraron Planes";
          tipe_swal = "info";
          console.log("No hizo match");
        } else {
          console.log("Hay match");
          var nameVariant = getPlan.data.plans[0].name;
          var PriceVariant;
          var PriceVariant1;
          var PriceVariant2;
          var QuantityVariant;
          var QuantityVariant1;
          var QuantityVariant2;
          var bodySku;
          var bodySku1;
          var bodySku2;
          var warrantySku;
          var warrantySku1;
          var warrantySku2;
          var textPlugin;
          var textPlugin1;
          var textPlugin2;
          console.log("Largo de los values");
          console.log(getPlan.data.plans[0].values.length);
          console.log(nameVariant);

          console.log(producto.product.variants);
          var variantBody;
          var BreakException = {};
          if (!getPlan.data.plans[0].values[0].validity.warranty_sku) {
            message_swal = "Error: No existe campo Warranty sku";
            title_swal = "Operación Fallido";
            tipe_swal = "error";
            throw BreakException;
          }
          if (getPlan.data.plans[0].values.length === 1) {
            //Create sku desde aca - Arriba todo ok
            QuantityVariant = getPlan.data.plans[0].values[0].validity.quantity;
            PriceVariant = getPlan.data.plans[0].values[0].price;

            //cambio
            textPlugin = getPlan.data.plans[0].values[0].validity.textPlugin;
            warrantySku = getPlan.data.plans[0].values[0].validity.warranty_sku;

            console.log(PriceVariant);
            console.log(QuantityVariant);
            bodySku = {
              product: {
                name: textPlugin, //Campo text plugin mongo db
                description: "Powered By EmbedX",
                page_title: "gext",
                meta_description: "gext",
                price: PriceVariant,
                weight: QuantityVariant,
                stock: 999,
                stock_unlimited: true,
                sku: warrantySku, // Warranty sku mongo db
                barcode: "test1",
                featured: false,
                shipping_required: true,
                status: "available",
                package_format: "box",
                length: 0,
                width: 0,
                height: 0,
                diameter: 0,
                permalink: "string",
                categories: [
                  {
                    id: 1237239,
                    name: "embedx-js",
                    parent_id: null,
                    permalink: "embedx-js",
                  },
                ],
              },
            };

            const sku = await axios.post(
              `${thresh}/jumpseller/createSkuWarranty`,
              bodySku,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: bToData.data.access_token,
                },
              }
            );

            console.log(sku.data.data);

            await axios.post(
              `${thresh}/jumpseller/createImageSku`,
              {
                id: sku.data.data.product.id,
                url: "https://lapolar.sbins.dev.perk.cl/embed.png",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: `${bToData.data.access_token}`,
                },
              }
            );

            variantBody = {
              validity: 1,
              name: nameVariant,
              quantity1: QuantityVariant,
              price1: PriceVariant,
              id1: sku.data.data.product.id,
              warrantySku: warrantySku,
              stock: producto.product.stock,
              sku: producto.product.sku,
            };

            console.log(variantBody);
          } else if (getPlan.data.plans[0].values.length === 2) {
            //Create sku desde aca
            QuantityVariant = getPlan.data.plans[0].values[0].validity.quantity;
            PriceVariant = getPlan.data.plans[0].values[0].price;
            textPlugin = getPlan.data.plans[0].values[0].validity.textPlugin;
            warrantySku = getPlan.data.plans[0].values[0].validity.warranty_sku;
            textPlugin1 = getPlan.data.plans[0].values[1].validity.textPlugin;
            warrantySku1 =
              getPlan.data.plans[0].values[1].validity.warranty_sku;
            QuantityVariant1 =
              getPlan.data.plans[0].values[1].validity.quantity;
            PriceVariant1 = getPlan.data.plans[0].values[1].price;

            bodySku = {
              product: {
                name: textPlugin,
                description: "Powered By EmbedX",
                page_title: "gext",
                meta_description: "gext",
                price: PriceVariant,
                weight: QuantityVariant,
                stock: 999,
                stock_unlimited: true,
                sku: warrantySku,
                barcode: "test1",
                featured: false,
                shipping_required: true,
                status: "available",
                package_format: "box",
                length: 0,
                width: 0,
                height: 0,
                diameter: 0,
                permalink: "string",
                categories: [
                  {
                    id: 1237239,
                    name: "embedx-js",
                    parent_id: null,
                    permalink: "embedx-js",
                  },
                ],
              },
            };

            bodySku1 = {
              product: {
                name: textPlugin1,
                description: "Powered By EmbedX",
                page_title: "gext",
                meta_description: "gext",
                price: PriceVariant1,
                weight: QuantityVariant1,
                stock: 999,
                stock_unlimited: true,
                sku: warrantySku1,
                barcode: "test1",
                featured: false,
                shipping_required: true,
                status: "available",
                package_format: "box",
                length: 0,
                width: 0,
                height: 0,
                diameter: 0,
                permalink: "string",
                categories: [
                  {
                    id: 1237239,
                    name: "embedx-js",
                    parent_id: null,
                    permalink: "embedx-js",
                  },
                ],
              },
            };

            const sku = await axios.post(
              `${thresh}/jumpseller/createSkuWarranty`,
              bodySku,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: bToData.data.access_token,
                },
              }
            );

            const sku1 = await axios.post(
              `${thresh}/jumpseller/createSkuWarranty`,
              bodySku1,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: bToData.data.access_token,
                },
              }
            );

            await axios.post(
              `${thresh}/jumpseller/createImageSku`,
              {
                id: sku.data.data.product.id,
                url: "https://lapolar.sbins.dev.perk.cl/embed.png",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: `${bToData.data.access_token}`,
                },
              }
            );

            await axios.post(
              `${thresh}/jumpseller/createImageSku`,
              {
                id: sku1.data.data.product.id,
                url: "https://lapolar.sbins.dev.perk.cl/embed.png",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: `${bToData.data.access_token}`,
                },
              }
            );
            console.log(nameVariant);

            console.log(QuantityVariant);
            console.log(PriceVariant);
            console.log(sku.data.data.product.id);
            console.log(QuantityVariant1);
            console.log(PriceVariant1);
            console.log(sku1.data.data.product.id);

            variantBody = {
              validity: 2,
              name: nameVariant,
              quantity1: QuantityVariant,
              price1: PriceVariant,
              id1: sku.data.data.product.id,
              quantity2: QuantityVariant1,
              price2: PriceVariant1,
              id2: sku1.data.data.product.id,
              warrantySku: warrantySku,
              warrantySku1: warrantySku1,
              stock: producto.product.stock,
              sku: producto.product.sku,
            };

            console.log(variantBody);
          } else if (getPlan.data.plans[0].values.length === 3) {
            //Create sku desde aca
            QuantityVariant = getPlan.data.plans[0].values[0].validity.quantity;
            PriceVariant = getPlan.data.plans[0].values[0].price;
            textPlugin = getPlan.data.plans[0].values[0].validity.textPlugin;
            warrantySku = getPlan.data.plans[0].values[0].validity.warranty_sku;

            QuantityVariant1 =
              getPlan.data.plans[0].values[1].validity.quantity;
            PriceVariant1 = getPlan.data.plans[0].values[1].price;
            textPlugin1 = getPlan.data.plans[0].values[1].validity.textPlugin;
            warrantySku1 =
              getPlan.data.plans[0].values[1].validity.warranty_sku;

            QuantityVariant2 =
              getPlan.data.plans[0].values[2].validity.quantity;
            PriceVariant2 = getPlan.data.plans[0].values[2].price;
            textPlugin2 = getPlan.data.plans[0].values[2].validity.textPlugin;
            warrantySku2 =
              getPlan.data.plans[0].values[2].validity.warranty_sku;

            bodySku = {
              product: {
                name: textPlugin,
                description: "Powered By EmbedX",
                page_title: "gext",
                meta_description: "gext",
                price: PriceVariant,
                weight: QuantityVariant,
                stock: 999,
                stock_unlimited: true,
                sku: warrantySku,
                barcode: "test1",
                featured: false,
                shipping_required: true,
                status: "available",
                package_format: "box",
                length: 0,
                width: 0,
                height: 0,
                diameter: 0,
                permalink: "string",
                categories: [
                  {
                    id: 1237239,
                    name: "embedx-js",
                    parent_id: null,
                    permalink: "embedx-js",
                  },
                ],
              },
            };

            bodySku1 = {
              product: {
                name: textPlugin1,
                description: "Powered By EmbedX",
                page_title: "gext",
                meta_description: "gext",
                price: PriceVariant1,
                weight: QuantityVariant1,
                stock: 999,
                stock_unlimited: true,
                sku: warrantySku1,
                barcode: "test1",
                featured: false,
                shipping_required: true,
                status: "available",
                package_format: "box",
                length: 0,
                width: 0,
                height: 0,
                diameter: 0,
                permalink: "string",
                categories: [
                  {
                    id: 1237239,
                    name: "embedx-js",
                    parent_id: null,
                    permalink: "embedx-js",
                  },
                ],
              },
            };

            bodySku2 = {
              product: {
                name: textPlugin2,
                description: "Powered By EmbedX",
                page_title: "gext",
                meta_description: "gext",
                price: PriceVariant2,
                weight: QuantityVariant2,
                stock: 999,
                stock_unlimited: true,
                sku: warrantySku2,
                barcode: "test1",
                featured: false,
                shipping_required: true,
                status: "available",
                package_format: "box",
                length: 0,
                width: 0,
                height: 0,
                diameter: 0,
                permalink: "string",
                categories: [
                  {
                    id: 1237239,
                    name: "embedx-js",
                    parent_id: null,
                    permalink: "embedx-js",
                  },
                ],
              },
            };
            var idsku;
            var idsku1;
            var idsku2;

            const sku = await axios.post(
              `${thresh}/jumpseller/createSkuWarranty`,
              bodySku,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: bToData.data.access_token,
                },
              }
            );

            const sku1 = await axios.post(
              `${thresh}/jumpseller/createSkuWarranty`,
              bodySku1,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: bToData.data.access_token,
                },
              }
            );

            const sku2 = await axios.post(
              `${thresh}/jumpseller/createSkuWarranty`,
              bodySku2,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  token: bToData.data.access_token,
                },
              }
            );

            if (sku.status === 202) {
              console.log(
                "No se crearan las imagenes debido a que los sku existen"
              );
              console.log(sku.data.data.id);
              idsku = sku.data.data.id;
              idsku1 = sku1.data.data.id;
              idsku2 = sku2.data.data.id;
            } else if (sku1.status === 202) {
              console.log(
                "No se crearan las imagenes debido a que los sku existen"
              );
              console.log(sku1.data.data.id);
              idsku = sku.data.data.id;
              idsku1 = sku1.data.data.id;
              idsku2 = sku2.data.data.id;
            } else if (sku2.status === 202) {
              console.log(
                "No se crearan las imagenes debido a que los sku existen"
              );
              console.log(sku2.data.data.id);
              idsku = sku.data.data.id;
              idsku1 = sku1.data.data.id;
              idsku2 = sku2.data.data.id;
            } else {
              //todo lo que se ejecuta si el sku no existe
              idsku = sku.data.data.product.id;
              idsku1 = sku1.data.data.product.id;
              idsku2 = sku2.data.data.product.id;
              await axios.post(
                `${thresh}/jumpseller/createImageSku`,
                {
                  id: idsku,
                  url: "https://lapolar.sbins.dev.perk.cl/embed.png",
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    token: `${bToData.data.access_token}`,
                  },
                }
              );

              await axios.post(
                `${thresh}/jumpseller/createImageSku`,
                {
                  id: idsku1,
                  url: "https://lapolar.sbins.dev.perk.cl/embed.png",
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    token: `${bToData.data.access_token}`,
                  },
                }
              );

              await axios.post(
                `${thresh}/jumpseller/createImageSku`,
                {
                  id: idsku2,
                  url: "https://lapolar.sbins.dev.perk.cl/embed.png",
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    token: `${bToData.data.access_token}`,
                  },
                }
              );
            }

            variantBody = {
              validity: 3,
              name: nameVariant,
              quantity1: QuantityVariant,
              price1: PriceVariant,
              id1: idsku,
              quantity2: QuantityVariant1,
              price2: PriceVariant1,
              id2: idsku1,
              quantity3: QuantityVariant2,
              price3: PriceVariant2,
              id3: idsku2,
              warrantySku: warrantySku,
              warrantySku1: warrantySku1,
              warrantySku2: warrantySku2,
              stock: producto.product.stock,
              sku: producto.product.sku,
              barcode: producto.product.barcode,
            };
            console.log(variantBody);
          } //ok hasta aca arriba

          if (producto.product.variants.length === 0) {
            //Crear si o si
            var id = producto.product.id;
            //aqui va variable global para el id
            idJumpseller = id;
            console.log("pasa aca");
            try {
              const Data = await axios.post(
                `${thresh}/jumpseller/createVariant?id=${id}`,
                variantBody,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    token: `${bToData.data.access_token}`,
                  },
                }
              );
              console.log(Data);
            } catch (error) {
              console.log(error);
            }

            var bodyRelation = {
              name_category_embedx: label_option_embed,
              id_plan_embedx: getPlan.data.plans[0]._id,
              name_category_js: js_option_embed,
              id_product_option_js: idJumpseller,
              name_sponsor_js: plan_filter,
              idUserEmbedx: embedData.data.id,
            };

            await axios.post(`${vault}/relation/create`, bodyRelation, {
              headers: {
                "x-access-token": embedData.accessToken,
              },
            });

            message_swal = "Garantias Creadas Exitosamente";
            title_swal = "Operación Exitosa";
            tipe_swal = "success";
          } else {
            console.log(producto.product.variants[0].options[0].name);
            var vari = [];
            await Promise.all(
              producto.product.variants.map(async (variantes) => {
                console.log("Logica cuando existe la variante");
                console.log(variantes.options);
                var sw = false;
                await Promise.all(
                  variantes.options.map(async (garantias) => {
                    console.log(garantias.name);
                    if (
                      garantias.name.includes("garantía") ||
                      garantias.name.includes("garantia")
                    ) {
                      console.log("no entra");
                      //console.log(garantias);
                      sw = false;
                      vari.push(garantias);
                      //break;
                    } else {
                      sw = true;
                    }
                    console.log(sw);
                  })
                );
                console.log("----------------");
                if (sw === true) {
                  //Crear garantias
                  id = producto.product.id;

                  const resJumpSeller2 = await axios.post(
                    `${thresh}/jumpseller/createVariant?id=${id}`,
                    variantBody,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        token: `${bToData.data.access_token}`,
                      },
                    }
                  );
                  console.log("data variantes");
                  console.log(resJumpSeller2);
                  //aca se viene la funcion para actualizar los sku y el stock.

                  getPlans(getPlan);
                  console.log(js_option_embed);
                  console.log(label_option_embed);
                  message_swal = "Garantias Creadas Exitosamente";
                  title_swal = "Operación Exitosa";
                  tipe_swal = "success";
                } else {
                  //mensaje que las garantias estan creadas
                  message_swal =
                    "Ya existen garantias para las categorias seleccionadas";
                  title_swal = "Informacion";
                  tipe_swal = "info";
                }

                // console.log(vari.length)
                // vari.forEach(function(value,index,array){
                //     console.log(array[index].name)
                // })
                //cambiar por for of recorrer arreglo y ver si contiene al menos una vez la palabra embedx
              })
            );
          }
        }
      }catch(error){
        console.log("exception: "+ error)
      }
      }
  

      changeStateLoading(plans);
      Swal.fire(title_swal, message_swal, tipe_swal);
    }
  };

  const DeleteWarranty = async (idRelation, idProductJS) => {
    var relation = idRelation;
    var product = idProductJS;
    var embedData = JSON.parse(localStorage.getItem("embedx"));
    Swal.fire({
      title:
        "Desea eliminar las garantias de las categorias Seleccionadas?" +
        idRelation +
        "  " +
        idProductJS,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log(embedData.accessToken);
        //BEGIN
        try {
          const responseDeleteWarranty = await axios.post(
            `${thresh}/jumpseller/deleteVariant`,
            {
              relation,
              product,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                token: bToData.data.access_token,
                xtoken: embedData.accessToken,
              },
            }
          );

          if (responseDeleteWarranty.status === 200) {
            window.location.reload(false);
            Swal.fire("Categorias Eliminadas Exitosamente", "", "success");
            return responseDeleteWarranty.status;
          } else {
            Swal.fire("Categorias No Fueron Eliminadas", "", "info");
          }
        } catch (error) {
          throw error;
        }
      } else if (result.isDenied) {
        Swal.fire("No se eliminaron las categorias", "", "info");
      }
    });
  };

  return (
    <div className="layout">
      <Navbar />
      <Sidebar />
      <div className="contenido">
        <section id="stepone">
          <h2>Ingrese sus credenciales de EmbedX</h2>
          <input
            {...register("user")}
            type="text"
            placeholder="Usuario"
            id="user"
            className="rich-text"
          />

          <input
            type="password"
            {...register("pass")}
            id="pass"
            placeholder="Contraseña"
            className="rich-text"
          />
          <button className="button" name="login" id="sesion" onClick={Login}>
            {" "}
            Iniciar Sesión{" "}
          </button>
        </section>
        <section id="steptwo">
          <h2>Match de categorías</h2>
          <label className="select">Categoría EmbedX:</label>
          <select
            className="select"
            {...register("embed_select")}
            id="embed_cat"
          >
            <option>{categorias_embx}</option>
          </select>
          <br />
          <label className="select">Categoría Jumpseller:</label>

          <select
            className="select"
            {...register("js_select")}
            id="jumpseller_cat"
          >
            <option>{categorias_embx}</option>
          </select>

          <button className="button" onClick={AddWarranty}>
            {" "}
            Añadir
          </button>
        </section>

        <section id="stepthree">
          <>
            <h2>Lista de categorías con garantía</h2>
            {loading === true && !categoria ? <Loader /> : <></>}

            {!categoria ? (
              <p className="select2">No hay datos para mostrar</p>
            ) : (
              <table className="table table-striped" id="tabla1">
                <thead className="header-table">
                  <tr>
                    <th scope="col" nowrap="true">
                      Categoria EmbedX
                    </th>
                    <th scope="col" nowrap="true">
                      ID Plan EmbedX
                    </th>
                    <th scope="col" nowrap="true">
                      Categoria JS
                    </th>
                    <th scope="col" nowrap="true">
                      Sponsor
                    </th>
                    <th scope="col" nowrap="true">
                      ID Usuario embedx
                    </th>
                    <th scope="col" nowrap="true">
                      Fecha de Creacion
                    </th>
                    <th scope="col" nowrap="true">
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categoria.map((item, index) => (
                    <tr key={index}>
                      <td nowrap="true">{item.name_category_embedx}</td>
                      <td nowrap="true">{item.id_plan_embedx}</td>
                      <td nowrap="true">{item.name_category_js}</td>
                      <td nowrap="true">{item.name_sponsor_js}</td>
                      <td nowrap="true">{item.idUserEmbedx}</td>
                      <td nowrap="true">{item.createdAt}</td>
                      <td nowrap="true">
                        <button
                          className="button"
                          name="delete"
                          onClick={() =>
                            DeleteWarranty(
                              item._id,
                              item.id_product_option_js[0]
                            )
                          }
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
          <div className="table"></div>
        </section>

        {/*<section id="steplogout">
                <br/>
                <h2>Opciones</h2>
                <button className="h2 button" onClick={Logout}> Salir </button>
            </section>*/}
      </div>
    </div>
  );
};
