import logo from "../embed.png";
import "../App.css";
import Swal from "sweetalert2";
//import swal from 'sweetalert';
import Welcome from "./Welcome";
var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
const status = urlParams.get("status");

if (status === "false") {
  //swal("Credenciales Invalidas","Error Inicio Sesion","error")
  Swal.fire(
    "Credenciales Invalidas",
    "Inicie sesion de manera correcta",
    "error"
  );
} else if (status === "true") {
  Swal.fire(
    "Cierre de Sesion Exisoso",
    "Cierre de Sesion de manera correcta.",
    "success"
  );
  //swal("Cerro Sesion Exitosamente","Operacion Exitosa","info")
}

export const Inicio = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>EmbedApp Jumpseller Plugin</p>
        <Welcome />
      </header>

      {/*Holamundo*/}
    </div>
  );
};

export default Inicio;
