import React from "react";

const Welcome = () => {
  //Declaracion de Variables Oauth2

  const auth = process.env.REACT_APP_AUTH_URL;
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const scope = process.env.REACT_APP_SCOPE;
  const url_callback = process.env.REACT_APP_CALLBACK;

  const click = async (e) => {
    console.log("Inicio Oauth2 by EmbedX");
    //var URL = `${auth}?client_id=${auth}`;
    // const getAuthorizationCode = oauth.client(axios.create(), {
    //     url: 'https://accounts.jumpseller.com/oauth/authorize',
    //     client_id: 'Y15JbRjjGS4V4JkeX6zWpADSmi_dpqflm3s_m372i58',
    //     redirect_uri: 'https://localhost:3000/',
    //     response_type: 'code',
    //     scope: 'read_products write_products read_hooks write_hooks read_orders write_orders',
    //   });

    var URL =
      auth +
      "?client_id=" +
      client_id +
      "&redirect_uri=" +
      url_callback +
      "&response_type=code&scope=" +
      scope;

    //   const auth = await getAuthorizationCode(); // => { "access_token": "...", "expires_in": 900, ... }
    window.location.href = URL;
  };

  return (
    <div>
      <button className="button" onClick={click}>
        {" "}
        Iniciar Sesion{" "}
      </button>
    </div>
  );
};

export default Welcome;
