import React from 'react'
import  {Routes, Route, BrowserRouter} from 'react-router-dom';
import { Inicio } from '../components/Inicio';
import  { Callback }  from '../components/Callback';
import { Home } from '../components/Home';


export const RouterPrincipal = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Inicio/>} />
            <Route path="/callback" element={<Callback/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/" element={<Home/>} />
        </Routes>
    </BrowserRouter>
  )
}
